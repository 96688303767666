<template>
  <div>
    <ModalCreateTerm
      v-if="showCreateTerm"
      :showModal="showCreateTerm"
      :onClickCancel="hideModal"
      :channelId="channelId"
      :termsType="termsType"
      @termCreated="getTerms"
    />

    <ModalDeleteTerm
      v-if="showDeleteTerm"
      :showModal="showDeleteTerm"
      :onClickCancel="hideModal"
      :term="selectedTerm"
      @termDeleted="getTerms"
    />

    <ModalActivateTerm
      v-if="showActivateTerm"
      :showModal="showActivateTerm"
      :onClickCancel="hideModal"
      :term="selectedTerm"
      @termActivated="getTerms"
    />

    <ui-page-hero :title="'Terms'" />

    <section class="section is-small">
      <div class="has-margin-bottom">
        <Message :message="$t('Views.Terms.BookingTerms.Message_Info')" />
      </div>
      <div
        class="actionBar has-background-white-ter has-padding has-margin-bottom"
      >
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <a @click="setShowCreateTerm" class="has-icon">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>{{
                $t('Views.Terms.BookingTerms.Button_CreateTerm')
              }}</span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <table class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>{{ $t('Views.Terms.BookingTerms.TableHeader_Version') }}</th>
          <th>{{ $t('Views.Terms.BookingTerms.TableHeader_CreatedOn') }}</th>
          <th>{{ $t('Views.Terms.BookingTerms.TableHeader_Status') }}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="term in terms" :key="term.Id">
          <td>
            <a @click="goToTermsDetail(term)">
              {{ term.Version }}
            </a>
          </td>
          <td>{{ term.CreatedOn | convertTimeStampToIsoDateTimeString }}</td>
          <td>
            <a @click="setShowActivateTerm(term)">
              <span v-if="term.Status === -1" class="tag">
                {{ $t('Views.Terms.BookingTerms.Tag_Archived') }}</span
              ></a
            >
            <span v-if="term.Status === 0" class="tag is-warning"
              ><a @click="setShowActivateTerm(term)">
                {{ $t('Views.Terms.BookingTerms.Tag_Draft') }}</a
              ></span
            >
            <span v-if="term.Status === 1" class="tag is-success">
              {{ $t('Views.Terms.BookingTerms.Tag_Active') }}</span
            >
          </td>
          <td class="no-wrap">
            <a @click="goToTermsDetail(term)">
              <span v-if="term.Status == 0" class="icon mr-1">
                <font-awesome-icon :icon="['fas', 'edit']" />
              </span>
            </a>

            <a @click="setShowDeleteTerm(term)">
              <span v-if="term.Status === 0" class="icon">
                <font-awesome-icon
                  :icon="['fas', 'trash-alt']"
                  style="color: red"
                />
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import termsProvider from '@/providers/terms'

export default {
  components: {
    ModalCreateTerm: () =>
      import(
        /* webpackChunkName: "terms" */ '@/components/Terms/ModalCreateTerm'
      ),
    ModalDeleteTerm: () =>
      import(
        /* webpackChunkName: "terms" */ '@/components/Terms/ModalDeleteTerm'
      ),
    ModalActivateTerm: () =>
      import(
        /* webpackChunkName: "terms" */ '@/components/Terms/ModalActivateTerm'
      ),
    Message: () =>
      import(/* webpackChunkName: "terms" */ '@/components/UI/Message'),
  },

  props: {
    termsType: {
      type: String,
      default: '',
      required: true,
    },
  },

  data() {
    return {
      terms: [],
      channelId: Number(this.$route.params.channelId),
      isLoading: false,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      selectedTerm: null,
      showCreateTerm: false,
      showDeleteTerm: false,
      showActivateTerm: false,
    }
  },

  computed: {},

  created() {
    this.setTerm(null)
    this.getTerms()
  },

  methods: {
    ...mapMutations('termStore', ['setTerm']),

    getTerms() {
      if (this.termsType === 'BookingTerm') {
        this.getBookingTerms()
      }

      if (this.termsType === 'PrivacyPolicy') {
        this.getPrivacyTerms()
      }
    },

    getBookingTerms() {
      let self = this

      if (!self.isLoading) {
        self.isLoading = true
        termsProvider.methods
          .getBookingTerms(this.channelId, this.locationId)
          .then((response) => {
            if (response.status === 200) {
              self.terms = response.data
            }
          })
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    getPrivacyTerms() {
      let self = this

      if (!self.isLoading) {
        self.isLoading = true
        termsProvider.methods
          .getPrivacyTerms(this.channelId, this.locationId)
          .then((response) => {
            if (response.status === 200) {
              self.terms = response.data
            }
          })
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    goToTermsDetail(term) {
      this.setTerm(term)

      this.$router.push({
        name: `termsDetail`,
        params: { termId: term.Id },
      })
    },

    setShowCreateTerm() {
      this.showCreateTerm = true
    },

    setShowDeleteTerm(term) {
      this.showDeleteTerm = true
      this.selectedTerm = term
    },

    setShowActivateTerm(term) {
      this.showActivateTerm = true
      this.selectedTerm = term
    },

    hideModal() {
      this.showCreateTerm = false
      this.showDeleteTerm = false
      this.showActivateTerm = false
      this.selectedTerm = null
    },
  },
}
</script>

<style></style>
